import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { graphql } from 'gatsby';

import Layout from '../components/Layout/layout';
import Seo from '../components/seo';
import '../styles/style.sass';
import MainScreenSecond from '../components/UI/MainScreenSecond/MainScreenSecond';

// import dataServicesInjection from '../db/dataServicesInjection';
import ServicesCatalogPage from '../components/Pages/ServicesCatalogPage/ServicesCatalogPage';
import Instagram from '../components/Layout/Instagram/Instagram';

const ServicesCatalog = ({data}) => {
  const { t } = useTranslation();
  
  const dataServices = data?.services?.nodes;
  const category = data?.categories

  const crumbsNav = [
    {
      id: 1,
      title_ua: 'Головна',
      title_ru: 'Главная',
      title_en: 'Home',
      to: '/',
    },
  ];

  return (
    <Layout whitwHeader={'active'}>
      <Seo title={t('Home')} />
      <MainScreenSecond
        mainData={category}
        crumbsNav={crumbsNav}
      />
      <ServicesCatalogPage dataServicesCatalog={dataServices} />
      <Instagram />
    </Layout>
  );
};

export default ServicesCatalog;

export const queryCategory = graphql`
  query Category($language: String!, $id: String!) {  
    services: allStrapiServices(filter: { services_category: { id: { eq: $id } } }){   
        nodes {
          strapiId
          title_ua
          title_ru
          title_en
          sub_title_ua
          sub_title_ru
          sub_title_en
          slug
          services_category {
            title_ua
            title_ru
            title_en
            slug
            } 
        }   
    }
    categories: strapiServicesCategories(strapiId: { eq: $id })  {
      title_ua
      title_ru
      title_en
      slug
      image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                  layout: FULL_WIDTH
                )
              }
            }
          }
  }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }`