import React from 'react';
import { Link } from 'gatsby-plugin-react-i18next';
import useLanguage from '../../../Hooks/useLanguage';

const SectionContent = ({ dataServicesCatalog }) => {
  const langToggle = useLanguage;
  return (
    <div className="section-content">
      <ul className="catalog-list">
        {(dataServicesCatalog || []).map((item, index) => {
          const count = index + 1;
            return (
              <li className="catalog-list__item" key={index}>
                <Link className="catalog-list__link" to={'/' + item?.slug}>
                  <span className="catalog-list__count">
                    {count <= 9 ? '0' + count + ' /' : count + ' /'}
                  </span>
                  <span className="catalog-list__wrapper">
                    <h2 className="catalog-list__title">
                      {langToggle(item?.title_ua, item?.title_ru, item?.title_en)}
                    </h2>
                  {langToggle(
                        item?.sub_title_ua,
                        item?.sub_title_ru,
                        item?.sub_title_en
                      ) &&  <p className="catalog-list__description">
                      {langToggle(
                        item?.sub_title_ua,
                        item?.sub_title_ru,
                        item?.sub_title_en
                      )}
                    </p>}
                  </span>
                  <span className="more-link">
                    {langToggle('Детальніше', 'Подробнее', 'Read more')}
                  </span>
                </Link>
              </li>
            );
    
        })}
      </ul>
    </div>
  );
};

export default SectionContent;
