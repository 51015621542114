import React from 'react';

import SectionContent from './SectionContent/SectionContent';
import SectionFooter from './SectionFooter/SectionFooter';

const ServicesCatalogPage = ({ dataServicesCatalog }) => {
  const sectionHours = {
    title_ua: 'Пн - СБ / 09:00 - 18:00',
    title_ru: 'Пн - СБ / 09:00 - 18:00',
    title_en: 'Mon - Sat / 09:00 - 18:00',
  };

  return (
    <section className="services-catalog">
      <div className="container-fluid">
        <SectionContent dataServicesCatalog={dataServicesCatalog} />
        <SectionFooter sectionHours={sectionHours} />
      </div>
    </section>
  );
};

export default ServicesCatalogPage;
